import Alpine from 'alpinejs';
import CircleType from 'circletype';
import Swiper from 'swiper';
import { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/modules';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

// Components
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

// Global
window.Alpine = Alpine;
window.CircleType = CircleType;
window.Swiper = Swiper;

// Inits
Swiper.use([Autoplay, EffectFade, Pagination, Navigation]);

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
window.gsap = gsap;
window.ScrollTrigger = ScrollTrigger;
window.ScrollToPlugin = ScrollToPlugin;

Alpine.start();